import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import moment from 'moment'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mySqlResults
    const siteTitle = this.props.data.site.siteMetadata.title
    const posted = moment(post.Posted).format("MMMM D, YYYY")
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.Title} />
        <header className="entry-header">
          <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: post.Title }}></h1>
          <small>{posted}</small>
        </header>
        <div dangerouslySetInnerHTML={{ __html: post.Body_html }} />
        <hr />
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            flexDirection: `row-reverse`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.url_title} rel="prev">
                <span dangerouslySetInnerHTML={{ __html: previous.Title }}></span> →
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.url_title} rel="next">
                ← <span dangerouslySetInnerHTML={{ __html: next.Title }}></span>
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mySqlResults(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      ID
      Status
      Section
      Title
      Posted
      Body_html
    }
  }
`
