import React from 'react'
import { Link } from 'gatsby'

import '../../static/style.css'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }

    return (
      <div className="container">
        <div className="header">
          {header}
        </div>
        <div className="top">
          <div className="nav">
            <Link to={`/`}>THE ARCHIVES</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://jesterkingbrewery.com/blog">NEW BLOG</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://jesterkingbrewery.com">MAIN SITE</a>
          </div>
        </div>
        <div id="main">
          <div className="content">
            {children}
          </div>
        </div>
        <footer className="footer">
          <div className="footerNav">
            <ul>
              <li>
                © 2019, revived by <strong><a href="https://craftpeak.com/" target="_blank" rel="noopener">Craftpeak</a></strong>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
